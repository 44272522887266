<template>
  <div  :class="{total_height:jointlytrue==true}" class="container">
      <md-result-page
        :img-url="require('@/assets/abd/image/null.png')"
        v-if="reportok === ''"
        subtext="要不然刷新试试？"
      />
    <div v-if="reportok === 'ok'" style="-webkit-overflow-scrolling: touch">
          <div class="detailtop" :style="'background:'+getStorage('theme','')">
      <img src="@/assets/abd/image/family.png"
           class="imgtouxiang"
           alt="123" />
      <div class="topname">家庭报告</div>
    </div>
    <div class="wrapper">
      <div class="caption">
        <span class="leftdiv iconfont iconyixianshi- context" :style="'color:'+getStorage('theme','')+'!important'"></span>
        <span class="leftword context" :style="'color:'+getStorage('theme','')+'!important'">家庭成员</span>
      </div>
      <table class="c-table"
             id="showBox" v-if="pageData.familyPerson && pageData.familyPerson.length !== 0">
        <thead>
          <tr>
            <th :style="'background:'+getStorage('theme','')">姓名</th>
            <th :style="'background:'+getStorage('theme','')">年龄</th>
            <th :style="'background:'+getStorage('theme','')">生日</th>
          </tr>
        </thead>
        <tr v-for="(itme,index) of pageData.familyPerson"
            :key="index">
          <td>{{itme.pname?itme.pname:'——'}}</td>
          <td>{{itme.ageStr?itme.ageStr:'——'}}</td>
          <td>{{itme.birthdate?itme.birthdate:'——'}}</td>
        </tr>
      </table>
      <p v-if="pageData.familyPerson && pageData.familyPerson.length === 0" class="nodata">暂无数据</p>
    </div>
    <div class="wrapper">
      <div class="caption">
        <span class="leftdiv context iconfont iconnavicon-qghztj" :style="'color:'+getStorage('theme','')+'!important'"></span>
        <span class="leftword context" :style="'color:'+getStorage('theme','')+'!important'">家庭保单汇总</span>
      </div>
      <p v-if="pageData.familyPeopleList&&pageData.familyPeopleList.length === 0" class="nodata">暂无数据</p>
      <div :style="'border:'+'1px solid '+ getStorage('theme','')" class="policy" v-if="pageData.familyPeopleList&&pageData.familyPeopleList.length !== 0">
        <div class="itme"
             v-for="(itme,index) of pageData.familyPeopleList"
             :key="index">
          <accordion :index="index">
            <span class="policyname">{{itme.name}}</span>
            <template #content>
              <ul class="content_box">
                <li>被保人:{{itme.pname}}</li>
                <li>保险公司:{{itme.companyname}}</li>
                <li>保单号:{{itme.policyno}}</li>
                <li>有限期:{{itme.liabdate}}</li>
              </ul>
            </template>
          </accordion>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <div class="caption">
        <span class="leftdiv context iconfont iconmingxiweihuyichang" :style="'color:'+getStorage('theme','')+'!important'"></span>
        <span class="leftword context" :style="'color:'+getStorage('theme','')+'!important'">月度保费支付明细</span>
        <span class="rightword">单位：元</span>
      </div>

      <ve-histogram :data="chartData"
                    :height="chartheight + 'px'"
                    :settings="chartSettings"
                    :colors="color"
                    :chartExtens="chartExtens"
                    :extend="chartExtend">
        <!-- <div class="danwei">（元）</div> -->
      </ve-histogram>
    </div>
    <div class="wrapper">
      <div class="caption">
        <span class="leftdiv context iconfont iconfeiyongchaxun1" :style="'color:'+getStorage('theme','')+'!important'"></span>
        <span class="leftword context" :style="'color:'+getStorage('theme','')+'!important'">家庭成员保费支付明细</span>
        <!-- <span class="rightword">单位：元</span> -->
      </div>
      <table class="c-table"
             id="showBox" v-if="pageData.creadList&&pageData.creadList.length !== 0">
        <thead>
          <tr>
            <th :style="'background:'+getStorage('theme','')">姓名</th>
            <th :style="'background:'+getStorage('theme','')">年度保费（元）</th>
          </tr>
        </thead>
        <tr v-for="(itme,index) of pageData.creadList"
            :key="index">
          <td>{{itme.pname}}</td>
          <th>{{itme.cost}}</th>
        </tr>
      </table>
      <p v-if="pageData.creadList&&pageData.creadList.length === 0" class="nodata">暂无数据</p>
    </div>
    <div class="wrapper">
      <div class="caption">
        <span class="leftdiv context iconfont iconfenxi" :style="'color:'+getStorage('theme','')+'!important'"></span>
        <span class="leftword context" :style="'color:'+getStorage('theme','')+'!important'">保单保障分析</span>
        <span class="rightword">单位：万元</span>
      </div>
      <div class="rows">
        <div class="lf">
          <table class="left-table"
                 border="1"
                 :style="'background:'+getStorage('theme','')"
                 align="center">
            <tr></tr>
            <tr>重疾</tr>
            <tr>意外</tr>
            <tr>寿险</tr>
            <tr>医疗</tr>
            <tr>养老</tr>
            <tr>教育</tr>
            <tr>理财</tr>
            <tr>姓名</tr>
          </table>
        </div>
        <div class="swp" v-if="pageData.foreheadList&&pageData.foreheadList.length !== 0">
           <swiper :options="swiperOption"
                  class="swiper-wrap"
                  v-if="pageData.foreheadList.length !== 0"
                  ref="mySwiper">
            <swiper-slide v-for="(item,index) of pageData.foreheadList"
                          :key="index">
              <table cellspacing="0"
                     cellpadding="0"
                     class="b-table analyze">
                <tr>
                  <th>参考</th>
                  <th>现有</th>
                </tr>
                <tr v-for="(it,i) of item.forehead"
                    :key="i">
                  <td>{{it.reference}}</td>
                  <td :style="'color:'+getStorage('theme','')">{{it.number}}</td>
                </tr>
                <tr class="lastname">
                  <td colspan="2">{{item.pname}}</td>
                </tr>
              </table>
            </swiper-slide>
            <div class="swiper-button-prev swiper-button-black"
                 v-show="showSign>1"
                 slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-black"
                 v-show="showSign>1"
                 slot="button-next"></div>
            <div class="swiper-pagination tag_dot"
                 slot="pagination"></div>
          </swiper>
          <div class="swiper-scrollbar" ></div>
        </div>
        <div class="swp noanydata" v-if="pageData.foreheadList && pageData.foreheadList.length === 0">
          <p  class="nodata">暂无数据</p>
        </div>
      </div>
    </div>
    <!--经纪人名片-->
    <!-- 修改名片弹框 -->
    <dig :show.sync="isupdatemsg"
         :istext="false"
         title="编辑经纪人名片信息">
      <md-field>
        <md-input-item title="姓名"
                       v-model="nickname"
                       clearable
                       placeholder="请输入经纪人姓名" />
        <md-input-item title="手机"
                       v-model="mobile"
                       clearable
                       placeholder="请输入经纪人手机号" />
      </md-field>
      <md-button class="showbtn"
                 size="large"
                 type="primary"
                 @click="submitmsg">提交</md-button>
    </dig>
    <!-- 家庭对应产品信息 -->
    <div class="wrapper" v-if="isuser||ensurebloo">
      <div class="caption">
        <span class="leftdiv context iconfont iconbaoxianchanpin" :style="'color:'+getStorage('theme','')+'!important'"></span>
        <span class="leftword context" :style="'color:'+getStorage('theme','')+'!important'">保障方案</span>
      </div>
      <div v-for="(item,index) of this.details" :key="index">
        <div class="product" v-if="isuser||item.jhsno" :style="'border:1px solid '+getStorage('theme','')">
          <div class="producttop" :style="'background:'+getStorage('theme','') + ';'+'border-bottom:1px solid '+ getStorage('theme','')">
          <p class="producttopname">{{item.panme}}的保障方案推荐</p>
        </div>
          <div v-for="(cpdata,index) of item.cplist" :key="index" :class="item.hcon?'productcentop':''">
            <div class="productcen" v-if="cpdata.checked">
              <div class="productcen-lf">
                <img :src="msossurl + cpdata.iconhttp" alt="">
              </div>
              <div class="productcen-rg">
                <div class="productcen-rg-cen">
                  <div class="productcen-rg-top">
                    <p>{{cpdata.cpname}}</p>
                  </div>
                  <div class="productcen-rg-bom">
                    <p>首年保费：{{cpdata.mount}}元</p>
                    <p class="productcenp">保额：{{cpdata.amount}}</p>
                  </div>
                </div>
              </div>
            </div>
        <!-- <div class="productcen" @click="particulars"> -->
          </div>
          <div class="premium">
            <p>首年总保费：<span :style="'color:'+getStorage('theme','')">{{item.premiumnum}}元</span></p>
          </div>
        <div class="productbom">
          <div :class="isuser?'productbomlf':'productbomlff'">
            <md-button size="large"
                   type="primary"
                   :style="'background:'+getStorage('theme','')"
                   @click="makeout(item)">{{isuser?'预览保障方案':'查看保障方案'}}</md-button>
          </div>
          <div class="productbomrg" v-if="isuser">
            <md-button size="large"
                   type="primary"
                   :style="'background:'+getStorage('theme','')"
                   @click="cpdataedit(item,'jiating')" >编辑</md-button>
          </div>
        </div>
      </div>
        </div>
    </div>
    <div class="wrapperlast">
      <div class="advice">
        <div class="caption1">
          <span class="leftdiv context iconfont iconbeizhuyitianxie" :style="'color:'+getStorage('theme','')+'!important'"></span>
          <span class="leftword context" :style="'color:'+getStorage('theme','')+'!important'">建议</span>
          <div class="bj_btn">
            <p class="iconfont iconbeizhu2 remark_color"
               @click="showMessageBox"
               v-if="!noShow"></p>
          </div>
        </div>
        <div class="expandingArea"
             id="textarea">
          <div class="remark" :style="'border:1px solid '+getStorage('theme','')">
            <pre class="contwords">{{ text }}</pre>
            <pre class="contwords nodatalist" v-if="text===''">暂无数据</pre>
          </div>
        </div>
      </div>
    </div>
    <dig :show.sync="isremark"
         title="优化建议"
         :istext="false">
      <textarea class="remark"
                v-model="remark"
                placeholder="请输入您的建议"></textarea>
      <div class="showbtn">
        <md-button size="large"
                   type="primary"
                   :style="'background-color:'+getStorage('theme','')+'!important'"
                   @click="submitremark">提交</md-button>
      </div>
    </dig>
    <!-- <div class="hw_sty"
         @click="toShare"
         v-if="!noShow"
         >
      <span class="iconfont iconfenxiang "></span>
      <span>分享</span>
    </div> -->
    <dig title="报告访问链接及密码"
         :istext="false"
         :show.sync="isDetail">
      <div class="links">
        <div class="txt">
          【保单整理】您有一份【{{familyname}}】的家庭解析报告,请戳以下链接并输入密码查看：{{redirect_uri}}/sharereport
          (验证码：{{verify}})。请您妥善保管本消息，切勿随意转发给其他人。
        </div>
        <div class="bt">
          <md-button size="large"
                     data-clipboard-target="#foo"
                     class="copy copybtn"
                     :disabled="sharebtn"
                     type="primary">{{btnText}}</md-button>
        </div>
        <div class="prompt">点“复制”后，在与客户本人（客户保单的投保人或被保人）的微信的聊天窗口中“粘贴”此消息并发送即可</div>
        <div id="foo">
          【保单整理】您有一份【{{familyname}}】的家庭解析报告,请戳以下链接并输入密码查看：
          {{`${redirect_uri}/sharereport?t=${params} (验证码：${verify})`}}。请您妥善保管本消息，切勿随意转发给其他人。</div>
      </div>
    </dig>
    </div>
    <div class="navsetting" v-if="!noShow&&user.rytype!=='Y'&&fromwhere=='app'">
      <div class="backbtn" :style="'height:'+'33%'" @click="backtopage">
        <span class="iconfont iconback"></span>
      </div>
      <div class="backtohome" :style="'height:'+'33%'" @click="backtohomepage" v-if="personkey==='0'">
        <span class="iconfont iconhome"></span>
      </div>
      <div class="shareicon" :style="'height:'+'33%'" @click="toShare">
        <span class="iconfont iconiconfontfenxiang2"></span>
      </div>
    </div>
    <div class="navsetting2" v-if="user.rytype==='Y'&&!noShow">
       <div class="backbtn" :style="'height:'+'100%'" @click="backtopage">
        <span class="iconfont iconback"></span>
      </div>
    </div>
    <div v-if="jointlytrue">
      <md-popup class="shelter_color" v-model="jointlytrue">
        <div class="jointlymold">
          <jointly ref="jointly" :form="form" @jointlymode="jointlymode"></jointly>
        </div>
    </md-popup>
    </div>
  </div>
  </div>
</template>
<script>
import accordion from "@/components/accordion";
import { prodlist } from "@/api/jhs/productlist";
import {
  examine,
  editpolicyremarks,
  updateFamilyRemarks,
  updatemsg,
  getreportno,
  selectFamilyReport,
  looksharereport,
  selectReportData,
  createFamilyReport,
} from "@/api/abd/family";
import { Toast } from "mand-mobile";
import ClipboardJS from "clipboard";
import dialog from "@/components/dialog";
import { Base64 } from "js-base64";
import config from "@/config";
import { wechatshare } from "@/lib/wechat_share";
import { getStorage, setStorage, AppShare } from "@/lib/util";
import store from "@/store";
import { DoRecord } from "@/api/abt/customerOperation/common/index";
import initWebSocket from "@/mixins/websock";
import jointly from "../../../jhs/productcheck/component/jointlyrespon";
const { redirect_uri, cvu } = config;

export default {
  components: {
    jointly,
    accordion,
    dig: dialog,
  },
  mixins: [initWebSocket],
  data() {
    this.chartSettings = {
      labelMap: {
        number: "保费支出",
      },
    };
    // dr-abd/image
    this.color = ["#11579D"];
    this.chartExtend = {
      legend: {
        selectedMode: false, // 取消图例上的点击事件
      },
    };
    this.chartExtens = {
      series: {
        label: {
          show: true,
          position: "insideRight",
          textStyle: {
            // dr-abd/image
            colors: "#11579D",
          },
          formatter: function (params) {
            if (params.value === 0) {
              return "";
            } else {
              return params.value;
            }
          },
        },
      },
    };
    return {
      msossurl: "",
      chartheight: "",
      sharebtn: false,
      reportok: "ok",
      swiperOption: {
        slidesPerView: 1,
        autoplay: false,
        // eslint-disable-next-line no-dupe-keys
        slidesPerView: "auto",
        // freeMode: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick: true,
        },
      },
      chartData: {
        columns: ["name", "number"],
        rows: [],
      },
      pageData: {},
      relation: {
        "00": "本人",
        "01": "丈夫",
        "02": "妻子",
        "03": "儿子",
        "04": "女儿",
        "05": "爸爸",
        "06": "妈妈",
        "07": "爷爷",
        "08": "奶奶",
        "09": "其他",
      },
      verify: null,
      isShow: false,
      sex: ["男", "女"],
      params: "",
      btnText: "一键复制",
      isDetail: false,
      noShow: false,
      text: "",
      remark: "",
      isremark: false,
      noedit: false,
      reportno: "",
      isupdatemsg: false,
      jjname: "",
      jjmobile: "",
      nickname: "",
      mobile: "",
      shortUrl: "",
      showSign: "",
      /* 家庭分析报告所需参数 */
      famreportno: "",
      famyzm: "",
      comid: getStorage("u_s", {}).comid,
      /* 行为追踪 */
      user: {},
      empno: "",
      rytype: "",
      suserid: "",
      userid: "",
      personkey: "",
      empuserid: "",
      familyname: "",
      fromwhere: "",
      proddatalist: [],
      details: [],
      isuser: false,
      userempno: "",
      ensure: 0,
      ensurebloo: false,
      form: [],
      jointlytrue: false,
    };
  },
  created() {
    this.msossurl = cvu;
    this.fromwhere = this.$route.query.fromwhere;
    this.familyname = this.$route.query.familyname;
    this.personkey = this.$route.query.personkey;
    this.empuserid = this.$route.query.empuserid;
    this.user = getStorage("u_s", {});
    this.empno =
      this.$route.query.empno && this.$route.query.empno !== ""
        ? this.$route.query.empno
        : this.user.empno;
    this.userempno = this.user.empno ? this.user.empno : "";
    this.rytype = this.$route.query.rytype
      ? this.$route.query.rytype
      : this.user.rytype;
    if (this.personkey === "1") {
      this.rytype = "W";
    }
    if (this.rytype === "Y") this.rytype = "W";
    this.suserid = this.$route.query.suserid
      ? this.$route.query.suserid
      : this.user.userid;
    this.userid = this.user.userid;
    if (this.$route.query.type || this.$route.query.sharetype) {
      this.noShow = true;
    }
    if (this.personkey === "1") {
      getreportno({
        familyno: this.$route.query.familyno,
        remarks: "",
        empuserid: this.$route.query.empuserid,
      }).then((res) => {
        this.famreportno = res.data.data.reportno;
        this.famyzm = res.data.data.yzm;
        if (this.fromwhere !== "app" && this.user.rytype === "W") {
          this.shareReport();
        }
        this.lookReport();
      });
      // selectReportData({ familyno: this.$route.query.familyno, comid: this.user.comid, reporttype: '2', empno: this.$route.query.empno }).then(res => {
      //   this.famreportno = res.data.data.reportno
      //   this.famyzm = res.data.data.yzm
      //   this.lookReport()
      // })
    } else {
      /* 微信分享出去 */
      if (this.$route.query.sharetype && this.user.userid !== this.suserid) {
        looksharereport({
          userid: this.user.userid,
          reportno: this.$route.query.reportno,
          yzm: this.$route.query.yzm,
          comid: this.$route.query.comid,
        }).then((res) => {
          console.log(res);
        });
        // if (this.fromwhere !== 'app') {
        //   this.behaviorRecord()
        // }
        if (this.fromwhere !== "app" && this.rytype === "W") {
          this.shareReport();
        }
        this.lookReport();
      } else {
        this.$route.query.type ? (this.noShow = true) : (this.noShow = false);
        if (this.$route.query.type) {
          if (this.$route.query.yzm) {
            this.lookReport();
          } else {
            let { familyno, comid, empno } = this.$route.query;
            createFamilyReport({ familyno, comid, empno }).then((res) => {
              this.famyzm = res.data.data.yzm;
              this.lookReport();
            });
          }
        } else {
          getreportno({
            familyno: this.$route.query.familyno,
            remarks: "",
            empuserid: "",
          }).then((res) => {
            this.famreportno = res.data.data.reportno;
            this.famyzm = res.data.data.yzm;
            if (this.fromwhere !== "app" && this.user.rytype === "W") {
              this.shareReport();
            }
            this.lookReport();
          });
        }
      }
    }
    // if (this.$route.query.ismanage) {
    //   this.noShow = true
    // }
    // this.isShow = !!this.$route.query.code
    new ClipboardJS(".copy").on("success", (e) => {
      this.btnText = "复制成功";
      this.sharebtn = true;
      e.clearSelection();
    });
  },
  mounted() {},
  beforeUpdate() {
    this.$nextTick(() => {
      this.chartheight = 400;
    });
  },
  methods: {
    shareReport() {
      let info = getStorage("u_s", {});
      if (!this.$route.query.type && this.user.userid !== this.suserid) {
        this.behaviorRecord();
      }
      let fxstr = {
        suserid: this.suserid,
        empno: this.empno,
        otype: "2",
        btagcode: "13",
        rytype: this.rytype,
        remark: this.familyname,
        scmap: {
          reportno: this.famreportno
            ? this.famreportno
            : this.$route.query.reportno,
        },
      };
      let shareurl = `${redirect_uri}/report?familyno=${
        this.$route.query.familyno
      }&reportno=${
        this.famreportno ? this.famreportno : this.$route.query.reportno
      }&yzm=${this.famyzm ? this.famyzm : this.$route.query.yzm}&comid=${
        info.comid
      }&empno=${this.empno}&recordtype=13&suserid=${this.user.userid}&rytype=${
        this.rytype
      }&sharetype=00&familyname=${this.familyname}`;
      if (this.user.rytype != "W") {
        // 不是业务员
        wechatshare("", "", "", "", "", "", true);
      } else {
        wechatshare(
          this.familyname + "的家庭解析报告",
          "您的好友【" +
            info.nickname +
            "】邀请您查看【" +
            this.familyname +
            "】的家庭解析报告",
          info.headimg,
          encodeURI(shareurl),
          redirect_uri,
          fxstr
        );
      }
    },
    // 查看报告
    lookReport() {
      this.comid = getStorage("u_s", {}).comid;
      examine({
        reportno: this.famreportno
          ? this.famreportno
          : this.$route.query.reportno,
        yzm: this.famyzm ? this.famyzm : this.$route.query.yzm,
        comid: this.comid ? this.comid : this.$route.query.comid,
      }).then((res) => {
        this.reportok = "ok";
        if (!res.data.data.parseObject) {
          this.reportok = "";
        }
        for (let item of res.data.data.parseObject.familyPerson) {
          let data = {
            familyno: item.familyno,
            memid: item.id,
            pname: item.pname,
          };
          this.proddatalist.push(data);
        }
        let data = {
          comid: this.comid ? this.comid : this.$route.query.comid,
          empno: this.empno,
          userempno: this.userempno,
          list: this.proddatalist,
        };
        prodlist(data).then((res) => {
          this.isuser = res.data.data.isuser;
          this.details = res.data.data.list;
          this.ensure = 0;
          for (let item in this.details) {
            let a = 0;
            this.details[item].cplist.forEach((v) => {
              if (v.checked) {
                a++;
                this.ensure++;
              }
            });
            if (a == 0) {
              this.details[item].hcon = true;
            }
          }
          if (this.ensure != 0) {
            this.ensurebloo = true;
          }
          for (let item in this.details) {
            let premiumnum = 0;
            this.details[item].cplist.forEach((v) => {
              if (v.checked) {
                premiumnum += parseFloat(v.mount);
              }
            });
            this.details[item].premiumnum = premiumnum;
          }
        });
        this.pageData = res.data.data.parseObject;
        this.showSign = this.pageData.foreheadList.length;
        this.text = res.data.data.parseObject.remarks;
        this.chartData.rows = this.pageData.premiumMap;
        store.state.app.isloading = false;
        this.$forceUpdate();
        this.verify = res.data.data.yzm;
        this.$route.query.reportno = this.famreportno;
        this.$route.query.yzm = this.famyzm;
        this.$route.query.comid = this.comid;
        this.$route.query.suserid = this.suserid;
        this.$route.query.empno = this.empno;
        this.params = Base64.encodeURI(
          JSON.stringify({
            path: {
              p: this.$route.path,
              query: this.$route.query,
            },
            verify: this.verify,
          })
        );
      });
    },
    // 查看产品列表对应信息
    particulars() {
      console.log("查看详情");
    },
    // 编辑产品列表对应信息
    cpdataedit(val, mold) {
      if (mold == "jiating") {
        let prod = [];
        for (let item in this.proddatalist) {
          if (val.memid == this.proddatalist[item].memid) {
            prod.push(this.proddatalist[item]);
          }
        }
        setStorage("proddatalist", prod);
        this.$router.push({
          path: "/productcheck",
          query: {
            mold: mold,
            personkey: this.$route.query.personkey,
            empuserid: this.$route.query.empuserid,
            familyname: this.$route.query.familyname,
            fromwhere: this.$route.query.fromwhere,
            type: this.$route.query.type,
            comid: this.comid,
            empno: this.empno,
            familyno: this.proddatalist[0].familyno,
            memid: val.memid,
          },
        });
      }
    },
    jointlymode() {
      // 显示计划书
      this.jointlytrue = false;
    },
    // 查看计划书
    makeout(val) {
      if (val.jhsno) {
        this.jointlytrue = true;
        this.form = JSON.parse(val.jhsno);
      } else {
        Toast.failed("尚未生成保障方案!");
      }
    },
    // 修改经纪人信息
    showUpdateMsg() {
      if (!this.noShow) {
        this.isupdatemsg = true;
        this.nickname = this.jjname;
        this.mobile = this.jjmobile;
      }
    },
    // 保存经纪人信息
    submitmsg() {
      let date = {
        abdMembUser: {
          nickname: this.nickname,
          mobile: this.mobile,
        },
      };
      updatemsg(date).then((res) => {
        this.jjname = this.nickname;
        this.jjmobile = this.mobile;
      });
      this.isupdatemsg = false;
    },

    toShare() {
      const jsonstring = {
        url: `${redirect_uri}/report?familyno=${
          this.$route.query.familyno
        }&reportno=${
          this.famreportno ? this.famreportno : this.$route.query.reportno
        }&yzm=${this.famyzm ? this.famyzm : this.$route.query.yzm}&comid=${
          this.user.comid
        }&empno=${this.empno}&recordtype=13&suserid=${
          this.user.userid
        }&rytype=${this.rytype}&sharetype=00&familyname=${this.familyname}`,
        title: this.familyname + "的家庭解析报告",
        description:
          "您的好友【" +
          this.user.empname +
          "】邀请您查看【" +
          this.familyname +
          "】的家庭解析报告",
        logoUrl: "",
      };
      AppShare(jsonstring);
      // this.btnText = '一键复制'
      // this.isDetail = !this.isDetail
    },
    showMessageBox() {
      if (!this.noShow) {
        this.isremark = !this.isremark;
        this.remark = this.text;
      }
    },
    submitremark() {
      editpolicyremarks({
        reportno: this.famreportno
          ? this.famreportno
          : this.$route.query.reportno,
        remarks: this.remark,
        comid: getStorage("u_s", {}).comid,
      }).then(() => {
        this.isremark = !this.isremark;
        this.text = this.remark;
        Toast.info("更新成功");
      });
    },
    behaviorRecord() {
      let data = {
        suserid: this.suserid,
        empno: this.empno,
        otype: "1",
        btagcode: "13",
        rytype: this.rytype,
        remark: this.familyname,
        scmap: {
          reportno: this.famreportno
            ? this.famreportno
            : this.$route.query.reportno,
        },
      };
      DoRecord(data).then((res) => {
        let id = res.data.data.id;
        let comid = this.user.comid;
        this.initWebSocket(id, comid); // 开启websocket连接
      });
    },
    backtopage() {
      this.$router.push({
        path: "/customercenter",
        query: {
          familyname: this.$route.query.familyname,
          familyno: this.$route.query.familyno,
          fromwhere: this.$route.query.fromwhere,
        },
      });
    },
    backtohomepage() {
      this.$router.push({
        path: "/bdtg",
        query: { fromwhere: this.$route.query.fromwhere, bdtgflush: true },
      });
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
};
</script>
<style scoped lang="stylus">
.detailtop {
  width: 100%;
  height: 368px;
  /* background-image: url("../../../../assets/abd/image/bg.png"); */
  background-color: color-primary;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  padding-top: 100px;
}

.btom {
  bottom: 0 !important;
}

.b-table {
  width: 100%;
}

.rows {
  display: flex;
}

.rows .lf {
  width: 33.3%;
}

.rows .swp {
  width: 66%;
}

.tag_dot {
  position: absolute;
  top: 617px;
}

#foo {
  opacity: 0;
  height: 0;
}

.links .txt {
  border: 1px dashed #81b7da;
  padding: 40px 20px;
  font-size: 36px;
}

.links .bt {
  padding: 20px;
}

/* .links button {
  background: #036eb5;
} */
.prompt {
  color: #848484;
  font-size: 36px;
}

.analyze thead th, .analyze td span {
  font-size: 18px;
}

.advice h3 {
  margin-bottom: 10px;
  font-size: 28px;
}

.container {
  background: #f5f4f7;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
}

.wrapper {
  background: #fff;
  padding: 45px 45px 60px;
  margin-bottom: 35px;
}

.wrapperlast {
  background: #fff;
  padding: 45px 45px 260px;
  line-height: 60px;
  margin-top: 40px;
}

.caption {
  /* height: 40px; */
  margin: 0 0 35px;
  overflow: hidden;
}

.caption .iconfont {
  font-size: 20px;
}

.title {
  text-align: center;
  margin-bottom: 50px;
}

.policy {
  padding: 0 20px;
  border: 1px solid color-primary;
  border-radius: 20px;
}

.policy .itme {
  padding: 30px 0;
  border-bottom: 1px solid #ebebeb;
}

.policy .itme:last-child {
  margin: 0;
}

.title h1 {
  font-size: 40px;
  margin-bottom: 20px;
}

.title h4 {
  font-size: 28px;
}

.c-table {
  border-collapse: collapse;
  margin: 0 auto;
  width: 100%;
}

.c-table td, .c-table tr, .c-table th {
  height: 73px;
  color: #1e1e1e;
  font-size: 26px;
  text-align: center;
  border: 1px solid #ddebfa;
}

.c-table thead th {
  /* font-weight: 400; */
  background-color: color-primary;
  font-size: 28px;
  color: #ffffff;
}

.c-table tr:nth-child(odd) {
  background: #fff;
}

.c-table tr:nth-child(even) {
  background: #f5fafa;
}

.b-table td, .b-table tr, .b-table th {
  line-height: 68px;
  color: #646464;
  font-size: 28px;
  text-align: center;
  width: 50%;
  border: 1px solid #ddebfa;
}

.b-table td:nth-child(2n) {
  color: color-primary;
}

.b-table tr {
  height: 68px;
  font-size: 28px;
}

.b-table th {
  background: #eef8fb;
}

.left-table {
  background-color: color-primary;
  color: #ffffff;
  /* font-weight: 400; */
  width: 100%;
  text-align: center;
  line-height: 70px;
}

.left-table tr {
  height: 70px;
  font-size: 28px;
}

.iconguanbi {
  color: #ff0000;
  font-size: 30px;
}

/* 标题 */
.leftdiv {
  display: inline-block;
  position: relative;
  top: 4px;
  /* width: px; */
  height: 30px;
  color: color-primary;
}

.leftword {
  font-size: 31px;
  margin-left: 5px;
  /* font-weight: bold; */
  letter-spacing: 3px;
  color: color-primary;
}

.rightword {
  float: right;
  font-size: 34px;
}

.policyname {
  font-size: 30px;
  color: #383838;
}

.content_box {
  margin-top: 8px;
}

.content_box li {
  font-size: 26px;
  color: #969696;
  padding: 10px;
}

.advicetitle {
  text-align: center;
  color: #383838;
  font-size: 33px;
}

.centercontent {
  text-align: center;
}

.centercontent p {
  text-align: right;
}

.centercontent span:nth-child(2n + 1) {
  display: inline-block;
  width: 2px;
  height: 18px;
  background-color: #bfbfbf;
  position: relative;
  bottom: 10px;
}

.centercontent span:nth-child(2n) {
  display: inline-block;
  width: 2px;
  height: 28px;
  background-color: #bfbfbf;
}

.remark {
  border: 1px solid color-primary;
  width: 100%;
  height: 80%;
  padding: 20px;
  min-height: 200px;
  border-radius: 15px;
}

.words {
  margin: 0 0 30px 5%;
}

.bj_btn {
  color: black;
  float: right;
  padding-top: 10px;
}

.icontianxie {
  font-size: 35px !important;
}

.infos {
  display: flex;
  align-items: center;
  font-size: 30px;
  padding: 20px 0;
  margin-right: 20px;
  border-bottom: 1px solid #ebebeb;
}

.infos div {
  float: right;
  text-align: right;
  flex: 1;
}

.man {
  color: #969696;
}

.words {
  margin: 0 0 30px 5%;
}

.update {
  width: 100%;
  padding: 20px;
  min-height: 200px;
}

.update span {
  margin-left: 10%;
}

.update input {
  width: 60%;
  padding: 20px 20px;
  border: 1px solid rgba(186, 216, 232, 0.4);
}

.caption1 {
  width: 100%;
  margin: 0 0 30px;
}

.caption1 .iconfont {
  font-size: 20px;
}

.jingjiren {
  border: 1px solid #3296fa;
  border-radius: 15px;
  padding: 20px 20px 0;
}

.imgtouxiang {
  width: 116px;
  height: 116px;
}

.topname {
  font-size: 31px;
  margin-top: 20px;
  /* font-weight: bold; */
  color: rgba(255, 255, 255, 1);
  letter-spacing: 3px;
}

.danwei {
  position: absolute;
  top: 40px;
  left: 10px;
  font-size: 13px;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 17px;
}

.showbtn {
  margin-top: 30px;
}

.editjjr {
  width: 90%;
  margin: 0 auto;
}

.hw_sty {
  height: 70px;
  width: 170px;
  position: fixed;
  background-color: color-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  right: 0;
  bottom: 40%;
  border-radius: 50px 0 0 50px;
}

.iconfenxiang {
  font-size: 40px;
  display: inline-block;
  display: flex;
  height: 60px;
  align-items: center;
}

.contwords {
  min-height: 180px;
  font-size: 30px;
  line-height: 50px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.nodatalist {
  display: flex;
  justify-content: center;
  color: #aaa;
  font-size: 35px;
}

pre {
  margin: 0;
}

.nodata {
  display: flex;
  justify-content: center;
  color: #aaa;
  font-size: 35px;
  padding-bottom: 10px;
}

.noanydata {
  display: flex;
  align-items: center;
  justify-content: center;
}

.context {
  font-size: 40px;
}

.copybtn {
  background-color: color-primary !important;
  margin: 14px 0;
}

.navsetting {
  width: 10vw;
  height: 30vw;
  border-radius: 10px;
  background-color: color-primary;
  position: fixed;
  right: 10px;
  z-index: 999;
  bottom: 50%;
}

.navsetting::after {
  content: '分享';
  color: white;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  border-radius: 0 0 10px 10px;
  width: 10vw;
  letter-spacing: 5px;
  background-color: color-primary;
}

.navsetting1 {
  width: 10vw;
  height: 20vw;
  border-radius: 10px;
  background-color: black;
  opacity: 0.38;
  position: fixed;
  right: 10px;
  z-index: 999;
  bottom: 40%;
}

.backbtn {
  width: 80%;
  margin: 0 auto;
  border-bottom: 0.8px solid rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 50px;
  }
}

.backtohome {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
    font-size: 50px;
  }
}

.shareicon {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  border-top: 0.8px solid rgba(255, 255, 255, 0.5);
  align-items: center;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
    font-size: 48px;
  }
}

.navsetting2 {
  width: 10vw;
  height: 10vw;
  border-radius: 10px;
  background-color: color-primary;
  position: fixed;
  right: 10px;
  z-index: 999;
  bottom: 40%;
}

.product {
  border: 1px solid color-primary;
  border-radius: 15px;
  margin-bottom: 30px;
}

.producttop {
  color: #fff;
  height: 80px;
  background-color: color-primary;
  border-bottom: 1px solid color-primary;
}

.producttopname {
  padding-left: 40px;
  height: 80px;
  line-height: 80px;
}

.premium p {
  color: #797979;
  padding-left: 40px;
  height: 80px;
  line-height: 80px;
}

.premium span {
  color: red;
}

.productcentop {
  height: 40px;
}

.productcen {
  display: flex;
  height: 250px;
  background: #fff;
  border-bottom: 20px solid #eee;
}

.productcen-lf {
  float: left;
  width: 30%;
  height: 100%;
  text-align: center;
  position: relative;
}

.productcen-lf img {
  border-radius: 5px;
  position: absolute; // 相对定位
  width: 180px;
  height: 160px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; // 使其垂直居中
}

.productcen-rg {
  float: left;
  width: 70%;
  height: 100%;
  position: relative;
}

.productcen-rg-cen {
  position: absolute; // 相对定位
  height: 160px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; // 使其垂直居中
}

.productcen-rg-top {
  height: 50%;
}

.productcen-rg-top p {
  padding-top: 3%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.productcen-rg-bom {
  display: flex;
}

.productcenp {
  margin-left: 10px;
}

.productcen-rg-bom p {
  padding-top: 3%;
  font-size: 26px;
  color: #808080;
}

.productbom {
  display: flex;
}

.productbomlff {
  width: 100%;
}

.productbomlf, .productbomrg {
  width: 49.5%;
}

.productbomrg {
  margin-left: 1%;
}

.jointlymold {
  width: 100%;
  height: 100%;
}

.shelter_color {
  height: 100vh;
  background-color: white;
}

.total_height {
  overflow: hidden;
}
</style>
